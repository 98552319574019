import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, timeoutWith } from 'rxjs/operators';
import { throwError, timer } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  token_response: any;
  token: any; 
  defaultTimeout = 1000000; // 30 seconds (adjust as needed)

  constructor(private http: HttpClient) { 
    // this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    // this.token = this.token_response.access_token;
    // console.log(this.token_response);
    // console.log(this.token_response.userId);
    // console.log(this.token_response.tenantId);
  }

  GetUserSitesAccessByUserProfileId() {
    return this.http.get(environment.GetUserSitesAccessByUserProfileId + '/' + this.token_response.userId  + '/' + this.token_response.tenantId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }


  // GetTenantUserTimeZone() {
  //   return this.http.get(environment.GetTenantUserTimeZone + '/' +  this.token_response.userId  + '/' + this.token_response.tenantId).pipe(map((res: any) => {
  //     return res;
  //   }), catchError(error => {
  //     console.log(error);
  //     return throwError(error);
  //   }));
  // }


  SaveFilesToS3Bucket(file: any, folderpath:any) {
   
    const formdata = new FormData();
    formdata.append('file', file);
    // formdata.append('tenantId', tenantid);
    formdata.append('folderpath',folderpath);
    return this.http.post(environment.UploadFile, formdata, {
      responseType: 'json',
      reportProgress: true,
    }).pipe(timeoutWith(this.defaultTimeout, timer(this.defaultTimeout)),map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetFileURL(fileName: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json'); // Adjust content type as needed
      return this.http.get(`${environment.GetFileURL}?filename=${fileName}`, {
        responseType: 'text',
        reportProgress: true,
        headers: headers
      }).pipe(
        timeoutWith(this.defaultTimeout, throwError(new Error('Timeout occurred'))),
        map((res: any) => {
          return res;
        }),
        catchError(error => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  GetGlobalSettingsByTenantId() {
    return this.http.get(environment.GetGlobalSettingsByTenantId + '/' + this.token_response.userId  + '/' + this.token_response.tenantId ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UpdateGlobalSettingsByTenantId(ScheduleData:any){
    return this.http.post(environment.UpdateGlobalSettingsByTenantId, ScheduleData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

 //Dobby----
 
  GetAllTimeZones(userProfileId:any) {
    return this.http.get(environment.GetAllTimeZones + '/' + userProfileId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetUserProfileDetailsByUserProfileId(userProfileId:any) {
    return this.http.get(environment.GetUserProfileDetailsByUserProfileId + '/' + userProfileId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  GetUserLoginActivityByUserProfileId(userProfileId:any, fromDate:any, toDate:any) {
    return this.http.get(environment.GetUserLoginActivityByUserProfileId + '/' + userProfileId + '/' + fromDate + '/' + toDate).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  UpdateUserProfileDetailsByUserProfileId(timeZoneData:any){
    return this.http.post(environment.UpdateUserProfileDetailsByUserProfileId, timeZoneData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}