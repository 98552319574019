<div class="ms-4 pt-4">
  <h5 class="newfontscolormain mt-1">Google Map</h5>
</div>
<div class="ms-1 me-1 pt-1">
  <agm-map #gm [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">

    <agm-marker *ngFor="let site of userAccessSites" [latitude]="latitude" [longitude]="longitude"
      >

      <!-- <agm-info-window #infoWindow>
        <strong class="text-danger"></strong>
        <div class="newfontscolor">{{site.SiteAddress
          }}<br>
         
        </div>
      </agm-info-window> -->
    </agm-marker>
  </agm-map>
</div>