<footer class="footer d-flex align-items-center">
    <div class="w-100 p-0">
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-lg-4 col-sm-4 newfontscolor">
                Support: <a href="mailto:support@burstbilling.com">support@burstbilling.com </a> | +1-(970) 779-0257 |
                <a routerLink="/Dobby/PrivacyPolicy" routerLinkActive="router-link-active">Privacy Policy</a>
            </div>
            <!-- <div class="col-lg-4 col-sm-4 newfontscolor">
               v {{version}}
            </div> -->
            <div class="col-lg-4 col-sm-4 text-center newfontscolor">
               <span *ngIf=" this.profilePicture"><img [src]="profilePicture" style="height: 30px; width: 30px;"
                class=""> |</span> {{tenantName}}
            </div>
            <div class="col-lg-4 col-sm-4">
                <div class="text-sm-end d-none d-sm-block">
                    <img src="assets/images/BurstBillingLogo.png" alt="" height="40px" width="150px" style="background-color: rgb(70, 97, 156);">
                    
                </div>
            </div>
        </div>
    </div>
</footer>