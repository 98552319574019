import { Component , OnInit,HostListener} from '@angular/core';
import { SharedService } from './core/services/SharedService.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  constructor(private inactivityService: SharedService) {
    // Start the inactivity timer when the app starts
    this.inactivityService.startTimer();
  }
  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
  @HostListener('window:mousemove') onMouseMove() {
    this.inactivityService.resetTimer();
  }

  @HostListener('window:scroll') onScroll() {
    this.inactivityService.resetTimer();
  }

  @HostListener('window:keypress') onKeyPress() {
    this.inactivityService.resetTimer();
  }
}
