import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, EventEmitter, Output } from '@angular/core';
// import MetisMenu from 'metismenujs/dist/metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';
import { UserLoginService } from 'src/app/core/services/user-login.service';
import { TostrAlertService } from 'src/app/core/services/tostr-alert.service';
import { SharedService } from 'src/app/core/services/SharedService.service';

type Tabs =  'Myprofile';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  activatedtabfile: Tabs = 'Myprofile';
  menuItems = [];
  token_response:any;
  token:any;
  userFirstName:any;
  userPicture:any;
  isUsernameVisible=false;

  
  @ViewChild('sideMenu') sideMenu: ElementRef;

  constructor(private eventService: EventService,private sharedService: SharedService, private userLoginService: UserLoginService, public toastralert: TostrAlertService, private router: Router, public translate: TranslateService, private http: HttpClient) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });

    this.token_response = JSON.parse(localStorage.getItem("siteaudit_token_response") || '{}');
    this.token = this.token_response.access_token;
    this.userFirstName = this.token_response .FirstName;


    console.log(this.userPicture);
  }
  @Output() mobileMenuButtonClicked = new EventEmitter();
  ngOnInit() {
    this.initialize();
    this._scrollElement();
    this.GetMenusByUserProfileIdAndUserRoleId();

    this.sharedService.getuserProfilePicture().subscribe((data) => {
      if(data){
        localStorage.setItem("UserPicture", data);
        this.userPicture = localStorage.getItem('UserPicture');
      }else{
        this.userPicture = localStorage.getItem('UserPicture');
      }
      console.log(this.userPicture);
    });
    console.log(this.userPicture);

    this.sharedService.getuserFirstName().subscribe((data) => {
      if(data){
        localStorage.setItem("UserFirstName", data);
        this.userFirstName = localStorage.getItem('UserFirstName');
      }else{
        this.userFirstName = localStorage.getItem('UserFirstName');
      }
      console.log(this.userFirstName);
    });
    console.log(this.userFirstName);
  }



  ngAfterViewInit() {
    // this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  logout() {
    localStorage.removeItem('siteaudit_token_response');
    localStorage.removeItem('IsUser_loggedin');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('Dateformat');
    localStorage.removeItem('Dateformat1');    
    localStorage.removeItem('Timeformat');
    localStorage.removeItem('Timeformat1');
    localStorage.removeItem('TimeZone');
    localStorage.removeItem('TimeZone1');    
    localStorage.removeItem('UserPicture');
    localStorage.removeItem('UserFirstName');
    localStorage.clear();
    this.router.navigate(['/login']);
    location.reload()

  }
  redirectProfile(){
    this.router.navigate(['/profile/general']);
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        // this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
        if(this.scrollRef.SimpleBar !== null)
          this.scrollRef.SimpleBar.getScrollElement().scrollTop =
            currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }

  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: any) {
    return item.LeftSubMenus !== undefined && item.LeftSubMenus !== null  ? item.LeftSubMenus.length > 0 : false;
  }

  toggleMobileMenu(event: any) {
    console.log("helo")
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
    this.isUsernameVisible = !this.isUsernameVisible;
  }

  GetMenusByUserProfileIdAndUserRoleId() {
    this.menuItems = [];
    this.userLoginService.GetMenusByUserProfileIdAndUserRoleId().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.menuItems = results;
        // console.log(this.menuItems)
        // this.menuItems[0].subItems = [{
        //   id: 11,
        //   label: 'MENUITEMS.CHAT.TEXT',
        //   icon: 'bx-chat',
        //   link: '/dashboard',
        //   parentId: 2

        // },
        // {
        //   id: 12,
        //   label: 'MENUITEMS.FILEMANAGER.TEXT',
        //   icon: 'bx-file',
        //   link: '/dashboard',
        //   parentId: 2
        // },]
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom("Unable to get menus.Please try again after some time.");
        }
      }
    );

  }
}
