import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token_response = JSON.parse(
      localStorage.getItem("siteaudit_token_response") || "{}"
    );
    const token = token_response.access_token;
   if (
      request.url.includes("https://localhost:44399/") ||
      request.url.includes("https://devapis.siteaudit.llc/") || 
      request.url.includes("https://qaapis.siteaudit.llc/")
    ) {
      const modifiedReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      });

      return next.handle(modifiedReq);
    }

    return next.handle(request);

    // const modifiedReq = request.clone({
    //   setHeaders: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    // });

    // return next.handle(modifiedReq);
  }
}
