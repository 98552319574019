import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  routeId: any;
  latitude: any;
  longitude: any;
  userAccessSites:any
  zoom = 12;
  fromAddresslat='-23.4567';
  fromadresslong='-986554';

  constructor(private router: Router, public route: ActivatedRoute, private titleService: Title,private profileService:ProfileService) {
    this.titleService.setTitle('Google Map');
    this.changeFavicon('assets/images/MapIcon.png');
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.latitude = +params['latitude']; // Convert to number
      this.longitude = +params['longitude'];
      console.log(this.latitude);
      console.log(this.longitude);
      this.GetUserSitesAccessByUserProfileId()
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const routeData = this.route.snapshot.data;
        this.titleService.setTitle(routeData.title || 'Default Title');
        this.changeFavicon(routeData.iconPath || 'assets/images/MapIcon.png');
      });
  }

  private changeFavicon(iconPath: string): void {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link['rel'] = 'icon';
    link['type'] = 'image/x-icon';
    link['href'] = iconPath;

    document.getElementsByTagName('head')[0].appendChild(link);
  }


  GetUserSitesAccessByUserProfileId() {
    console.log("helo");
    this.profileService.GetUserSitesAccessByUserProfileId().subscribe((results) => {
      console.log("helo");
      if (results) { 
        this.userAccessSites = results;
        console.log(this.userAccessSites, "contacts");
      
      }
    },
      (err) => {
        console.log("Error Occurred While Getting Dynamic Form Components Data.");
      });
  }

  
onMouseOver(infoWindow, gm) {

  if (gm.lastOpen != null) {
      gm.lastOpen.close();
  }

  gm.lastOpen = infoWindow;

  infoWindow.open();
}
}


