// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const url = "https://localhost:44380";
const url= "https://devapis.burstbilling.com"
//const url= "https://apis.burstbilling.com"
//  const url= "https://qaapis.burstbilling.com"
//const pythonUrl="http://localhost:5000"
const pythonUrl="https://pdfreaderdev.burstbilling.com"
//const pythonUrl="https://pdfreader.burstbilling.com"
export const environment = {
  Version: '0.0.0.1',
  production: false,
  tokenUrl: url + '/api/token',
  defaultauth: 'fackbackend',
  recaptcha: {
    siteKey: '6Ld-sUYpAAAAADNTTTNYiZyUn5iv0W_IfyG0zaCv',
  },
  validImageExtensions : ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg'],
  validFileExtensions : ["", 'docx', 'doc', 'pdf', 'csv', 'txt', 'text', 'xlsx', 'pptx', 'xml', 'ppt', 'psd', 'xls','jpeg', 'jpg', 'png', 'bmp', 'gif', 'svg',
                         'mp4', 'avi', 'flv', 'm4a', 'mov', 'mpg', 'mwv', 'm4v', '3gp', 'mkv', 'wmv', 'webm','mp3', 'wav', 'aac', 'flac', 'm4a', 'ogg', 'aiff', 'au',
                        'cshtml','html','css', 'js','json','cs','config','ts','csproj','php','sql'],

  
  //Dobby
  GetOrganizations: url + '/api/Organization/GetOrganizations',
  GetFacilitiesByOrganizationId: url + '/api/Organization/GetFacilitiesByOrganizationId',
  GetPreviousMonthsAndYear: url + '/api/Organization/GetPreviousMonthsAndYear',
  GetPreviousYears: url + '/api/Organization/GetPreviousYears',
  GetFaceSheetDetailsByOrganizationId: url + '/api/Organization/GetFaceSheetDetailsByOrganizationId',
  GetAllTimeZones: url + '/api/Profile/GetAllTimeZones',
  GetUserProfileDetailsByUserProfileId: url + '/api/Users/GetUserProfileDetailsByUserProfileId',
  GetUserLoginActivityByUserProfileId: url + '/api/Profile/GetUserLoginActivityByUserProfileId',
  UpdateUserProfileDetailsByUserProfileId: url + '/api/Profile/UpdateUserProfileDetailsByUserProfileId',
  GetFileURL: url + '/api/FileUpload/GetFileURL',
  GetAuthURLForPointClickCare: url + '/api/OAuth/GetAuthURLForPointClickCare',
  GetMarTarByOrganizationIdv1: url + '/api/Organization/GetMarTarByOrganizationIdv1',
  GetUnitsByFacilityId: url + '/api/Organization/GetUnitsByFacilityId',
  SaveMarTarImport:url+'/api/Organization/SaveMarTarImport',
  GetKeyWordInclusionsSelectActive:url+'/api/Organization/GetKeyWordInclusionsSelectActive',
  GetMarTarImport:url+'/api/Organization/GetMarTarImport',
  SaveKeyWordInclusions : url + '/api/Organization/SaveKeyWordInclusions',
  UpdateKeyWordInclusions : url + '/api/Organization/UpdateKeyWordInclusions',
  SaveUpdateCategoriesByCategoryId : url + '/api/Organization/SaveUpdateCategoriesByCategoryId',
  GetCategoriesSelectAll : url + '/api/Organization/GetCategoriesSelectAll',
  GetKeyWordInclusionsSelectByCategoryId : url + '/api/Organization/GetKeyWordInclusionsSelectByCategoryId',
  SaveUpdateKeyWordCategoriesUpsertKeyWordCategoryId : url + '/api/Organization/SaveUpdateKeyWordCategoriesUpsertKeyWordCategoryId',
  DeleteCategoriesDeleteCategoryId : url + '/api/Organization/DeleteCategoriesDeleteCategoryId',
  GetCensusDataByFacilityId : url + '/api/Organization/GetCensusDataByFacilityId',
  GetCensusFaceSheetDetailsById : url + '/api/Organization/GetCensusFaceSheetDetailsById',
  GetMarTarPatientsSelectByOrganizationUUId : url + '/api/Organization/GetMarTarPatientsSelectByOrganizationUUId',
  //Python Apis
  ImportMartarFile:pythonUrl+'/pdf/upload-pdf',
  //---------------------------------------------------------------------------------------//
  GetMenusByUserProfileIdAndUserRoleId: url + '/api/Users/GetMenusByUserProfileIdAndUserRoleId',
  GetUserDetailsByPhoneNumber: url + '/api/Users/SaveGetUserByPhoneNumber',
  GetSitesByTenantId: url + '/api/SiteAudit/GetSitesByTenantId',
  GetSetUpCategoriesOutOfTheBox: url + '/api/SiteAudit/GetSetUpCategoriesOutOfTheBox',
  SaveOrUpdateSetUpSites: url + '/api/SiteAudit/SaveOrUpdateSetUpSites',
  SaveNewUserDetails:url + '/api/Users/SaveNewUserDetails',
  SaveSetUpCategoriesAndSubCategories:url + '/api/SiteAudit/SaveSetUpCategoriesAndSubCategories',
  SaveSystemDateTime: url + '/api/SiteAudit/SaveSystemDateTime',
  GetSetUpSitesAndCategories: url + '/api/SiteAudit/GetSetUpSitesAndCategories',
  SaveContactDetails: url + '/api/SiteAudit/SaveOrUpdateContactDetails',
  GetAllContactDetailsByTenantId: url + '/api/SiteAudit/GetAllContactDetailsByTenantId',
  SaveDeleteContactDetailsByContactId: url + '/api/SiteAudit/SaveDeleteContactDetailsByContactId',
  GetContactDetailsByContactId: url + '/api/SiteAudit/GetContactDetailsByContactId',
  SaveAuditSiteOccupancyData: url + '/api/SiteAudit/SaveAuditSiteOccupancyData',
  SaveOrUpdateSiteGroupFamilies: url + '/api/Sites/SaveOrUpdateSiteGroupFamilies',
  GetSiteGroupFamiliesByTenantId: url + '/api/Sites/GetSiteGroupFamiliesByTenantId',
  GetSiteGroupFamilyByFamilyId: url + '/api/Sites/GetSiteGroupFamilyByFamilyId',
  DeleteSiteGroupFamilybyFamilyId: url + '/api/Sites/DeleteSiteGroupFamilybyFamilyId',
  SaveOrUpdateJsonData: url + '/api/SiteAudit/SaveOrUpdateJsonData',
  GetJsonData: url + '/api/SiteAudit/GetJsonData',
  GetTemplateCoversByTenantId: url + '/api/Template/GetTemplateCoversByTenantId',
  SaveOrUpdateTemplateCovers: url + '/api/Template/SaveOrUpdateTemplateCovers',
  GetSiteGroupsAndSitesBySiteGroupFamilyId: url + '/api/Sites/GetSiteGroupsAndSitesBySiteGroupFamilyId',
  SaveOrUpdateSiteGroups: url + '/api/Sites/SaveOrUpdateSiteGroups',
  GetSiteGroupByGroupId: url + '/api/Sites/GetSiteGroupByGroupId',
  DeleteSiteGroupbyGroupId: url + '/api/Sites/DeleteSiteGroupbyGroupId',
  GetUnassignedSitesBySiteGroupFamilyId: url + '/api/Sites/GetUnassignedSitesBySiteGroupFamilyId',
  GetTemplateCoversByTemplateCoverId: url + '/api/Template/GetTemplateCoversByTemplateCoverId',
  GetAllQuestionTypes: url + '/api/Template/GetAllQuestionTypes',
  SaveOrUpdateSitesInSiteGroups: url + '/api/Sites/SaveOrUpdateSitesInSiteGroups',
  GetAllContactTypes: url + '/api/Sites/GetAllContactTypes', 
  GetContactNameBySearchString: url + '/api/Sites/GetContactNameBySearchString',
  SaveSiteContacts: url + '/api/Sites/SaveOrUpdateSiteContacts',
  SaveSites: url + '/api/Sites/SaveSites',
  Get_SitesByTenantId: url + '/api/Sites/GetSitesByTenantId',
  SaveOrUpdateTemplatePages : url + '/api/Template/SaveOrUpdateTemplatePages',
  SaveOrUpdateTemplateSections : url + '/api/Template/SaveOrUpdateTemplateSections',
  GetSitesById: url + '/api/Sites/GetSitesByTenantId',
  GetSitesBySiteId: url + '/api/Sites/GetSitesBySiteId',
  SaveUsersToSites: url + '/api/Sites/SaveUsersToSites',
  GetUserProfilesByTenantId: url + '/api/Sites/GetUserProfilesByTenantId',
  SaveOrUpdateTemplateSchedules: url + '/api/Template/SaveOrUpdateTemplateSchedules',
  SaveOrUpdateTemplateQuestions : url + '/api/Template/SaveOrUpdateTemplateQuestions',
  GetTemplateCoverDetailsByTemplateCoverId : url + '/api/Template/GetTemplateCoverDetailsByTemplateCoverId',
  GetSiteContactsBySiteId: url + '/api/Sites/GetSiteContactsBySiteId',
  GetUserSitePermissionsByTenantId: url + '/api/Sites/GetUserSitePermissionsByTenantId',
  DeleteUserFromSite: url + '/api/Sites/DeleteUserFromSite',
  GetUserSitePermissionsByUserId: url + '/api/Sites/GetUserSitePermissionsByUserId',
  GetSchedulesViewByTenantId: url + '/api/SiteAudit/GetSchedulesViewByTenantId',
  GetUserSitesAccessByUserProfileId: url + '/api/Profile/GetUserSitesAccessByUserProfileId',
  GetSchedulesViewByTemplateCoverId: url + '/api/Template/GetSchedulesViewByTemplateCoverId',
  GetSiteContactsBySiteContactId: url + '/api/Sites/GetSiteContactsBySiteContactId',
  DeleteSiteContactBySiteContactId: url + '/api/Sites/DeleteSiteContactBySiteContactId',
  // GetTenantUserTimeZone: url + '/api/Profile/GetTenantUserTimeZone',
  SaveOrUpdateTemplatePagesDisplayOrder: url + '/api/Template/SaveOrUpdateTemplatePagesDisplayOrder',
  SaveOrUpdateTemplateSectionsDisplayOrder: url + '/api/Template/SaveOrUpdateTemplateSectionsDisplayOrder',
  UpdateTemplateQuestionsDisplayOrder: url + '/api/Template/UpdateTemplateQuestionsDisplayOrder',
  SaveDeleteTemplatePageByTemplatePageId : url + '/api/Template/SaveDeleteTemplatePageByTemplatePageId',
  SaveDeleteTemplateSectionByTemplateSectionId : url + '/api/Template/SaveDeleteTemplateSectionByTemplateSectionId',
  SaveDeleteTemplateQuestionByTemplateQuestionId : url + '/api/Template/SaveDeleteTemplateQuestionByTemplateQuestionId',
  GetSchedulesByTemplateCoverId : url + '/api/Template/GetSchedulesByTemplateCoverId',
  DeleteTemplateCoverById : url + '/api/Template/DeleteTemplateCoverById',
  UploadFile: url + '/api/FileUpload/UploadFile',
  GetGlobalSettingsByTenantId: url + '/api/Profile/GetGlobalSettingsByTenantId',
  UpdateGlobalSettingsByTenantId: url + '/api/Profile/UpdateGlobalSettingsByTenantId',
  GetUserPreferencesDetails: url + '/api/SiteAudit/GetUserPreferencesDetails',
  SaveUserPreferenceDetails: url + '/api/SiteAudit/SaveUserPreferenceDetails',
  GetTemplateFolderByTenantId: url + '/api/Template/GetTemplateFolderByTenantId',
  appDSBAuditsById: url + '/api/SiteAudit/GetDSBAuditsById',
  GetTemplateSchedulesBySiteId: url + '/api/Sites/GetTemplateSchedulesBySiteId',
  GetSitesBySearchString: url + '/api/Sites/GetSitesBySearchString',
  appDSBUpcomingAuditStatistics: url + '/api/SiteAudit/appDSBUpcomingAuditStatistics',
  DSBAuditIssueStatistics: url + '/api/SiteAudit/DSBAuditIssueStatistics',
  DSBAuditActionStatistics: url + '/api/SiteAudit/DSBAuditActionStatistics',
  GetUserAssignedSitesByTenantId: url + '/api/Sites/GetUserAssignedSitesByTenantId',
  GetTemplateMultipleChoicesByTemplateCoverId: url + '/api/Template/GetTemplateMultipleChoicesByTemplateCoverId',
  SaveOrUpdateTemplateMultipleChoice: url + '/api/Template/SaveOrUpdateTemplateMultipleChoice',
  GetTemplateQuestionsByTemplateMultipleChoiceId: url + '/api/Template/GetTemplateQuestionsByTemplateMultipleChoiceId',
  SaveOrUpdateTenantMultipleChoice: url + '/api/Template/SaveOrUpdateTenantMultipleChoice',
  SaveDeleteTemplateMultipleChoiceById: url + '/api/Template/SaveDeleteTemplateMultipleChoiceById',
  GetTenantMultipleChoicesByTenantId: url + '/api/Template/GetTenantMultipleChoicesByTenantId',
  DeleteTenantMultipleChoiceById: url + '/api/Template/DeleteTenantMultipleChoiceById',
  GetTenantMultipleChoiceById: url + '/api/Template/GetTenantMultipleChoiceById',
  GetTemplateNamesByTenantMultipleChoiceId: url + '/api/Template/GetTemplateNamesByTenantMultipleChoiceId',
  ArchiveTenantMultipleChoiceById: url + '/api/Template/ArchiveTenantMultipleChoiceById',
  SaveAudits: url + '/api/SiteAudit/SaveAudits',
  GetAuditCoverDetailsbyAuditCoverId: url + '/api/Audits/GetAuditCoverDetailsbyAuditCoverId',
  SaveOrUpdateAuditResponses: url + '/api/Audits/SaveOrUpdateAuditResponses',
  SaveOrUpdateAuditActions: url + '/api/Audits/SaveOrUpdateAuditActions',
  SaveOrUpdateAuditAttachments: url + '/api/Audits/SaveOrUpdateAuditAttachments',
  GetAuditsInProgressByTenantId: url + '/api/Audits/GetAuditsInProgressByTenantId',
  GetAuditsCompletedByTenantId: url + '/api/Audits/GetAuditsCompletedByTenantId',
  GetUsersAssignedToSiteBySiteId: url + '/api/Audits/GetUsersAssignedToSiteBySiteId',
  GetSeverityTypes: url + '/api/Audits/GetSeverityTypes',
  SaveOrUpdateAuditNotes: url + '/api/Audits/SaveOrUpdateAuditNotes',
  SaveOrUpdateAuditCompletion: url + '/api/Audits/SaveOrUpdateAuditCompletion',
  DeleteAuditAttachment: url + '/api/Audits/DeleteAuditAttachment',
  DeleteAuditAction: url + '/api/Audits/DeleteAuditAction',
  DeleteAuditNote: url + '/api/Audits/DeleteAuditNote',
  GetUserRolesByTenantId: url+ '/api/Users/GetUserRolesByTenantId',
  GetUserpermissionsByTenantId: url+ '/api/UserDetails/GetUserpermissionsByTenantId',
  SaveUpdateUserPermissionsById: url+ '/api/UserDetails/SaveUpdateUserPermissionsById',
  GetDSBAuditsInProgressByUserProfileId: url + '/api/SiteAudit/GetDSBAuditsInProgressByUserProfileId',
  GetAuditActionsByTenantId: url + '/api/Audits/GetAuditActionsByTenantId',
  appDSBInProgressAuditStatistics: url + '/api/SiteAudit/appDSBInProgressAuditStatistics',
  GetAuditIssuesByTenantId: url+ '/api/Audits/GetAuditIssuesByTenantId',
  GetIssueTypesByTenantId: url+ '/api/Audits/GetIssueTypesByTenantId',
  SaveOrUpdateappAuditIssues: url+'/api/Audits/SaveOrUpdateappAuditIssues',
  GetIssuesTypes : url+ '/api/Audits/GetIssuesTypes',
  ValidateReCaptchaResponse: url+ '/api/Users/ValidateReCaptchaResponse',
  GetAuditActionById: url+ '/api/Audits/GetAuditActionById',
  GetAuditIssueById :  url+ '/api/Audits/GetAuditIssueById',  
  GetDSBAuditIssuesAndActions: url + '/api/SiteAudit/GetDSBAuditIssuesAndActions',
  DeleteAuditIssue : url + '/api/Audits/DeleteAuditIssue',
  GetAuditStatisticsBySiteId : url + '/api/Sites/GetAuditStatisticsBySiteId',
  GetPastAndFutureAuditsBySiteId : url + '/api/Sites/GetPastAndFutureAuditsBySiteId',
  GetAuditIssuesBySiteId : url + '/api/Audits/GetAuditIssuesBySiteId',
  GetAuditActionsBySiteId : url + '/api/Audits/GetAuditActionsBySiteId',
  GetTenantSettingsByTenantId: url + '/api/TenantSettings/GetTenantSettingsByTenantId',
  UpdateTenantSettingsByTenantId: url + '/api/Profile/UpdateTenantSettingsByTenantId',
  GetAuditTitlesBySiteId: url + '/api/SiteAudit/GetAuditTitlesBySiteId',
  GetAuditSiteOccupancyData: url + '/api/SiteAudit/GetAuditSiteOccupancyData',
  GetCompletedAuditReportByAuditCoverId : url + '/api/Audits/GetCompletedAuditReportByAuditCoverId',
  SaveOrUpdateNewUserForTenant : url + '/api/UserDetails/SaveOrUpdateNewUserForTenant',
  GetUserProfilesAndRolesByTenantId : url + '/api/UserDetails/GetUserProfilesAndRolesByTenantId',
  UpdateUserRoleById : url + '/api/UserDetails/UpdateUserRoleById',
  appGetUserRolesByTenantId : url + '/api/UserDetails/GetUserRolesByTenantId',
  UpdateUserStatusById : url + '/api/UserDetails/UpdateUserStatusById',
  GenarateAuditReportByAuditCoverId : url + '/api/AuditReports/GenarateAuditReportByAuditCoverId',
  

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
