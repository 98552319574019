import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'MENUITEMS.MENU.TEXT',
    //     isTitle: true
    // },
    // {
    //     id: 2,
    //     label: 'MENUITEMS.DASHBOARDS.TEXT',
    //     icon: 'bx-home-circle',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.DEFAULT',
    //             link: '/dashboard',
    //             parentId: 2
    //         },
    //         {
    //             id: 4,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.SAAS',
    //             link: '/dashboards/saas',
    //             parentId: 2
    //         },
    //         {
    //             id: 5,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.CRYPTO',
    //             link: '/dashboards/crypto',
    //             parentId: 2
    //         },
    //         {
    //             id: 6,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.BLOG',
    //             link: '/dashboards/blog',
    //             parentId: 2
    //         },
    //         {
    //             id: 7,
    //             label: 'MENUITEMS.DASHBOARDS.LIST.JOBS',
    //             link: '/dashboards/jobs',
    //             parentId: 2,
    //             badge: {
    //                 variant: 'success',
    //                 text: 'MENUITEMS.FILEMANAGER.BADGE',
    //             },
    //         },
    //     ]
    // },
    {
        id: 8,
        isLayout: true
    },
    {
        id: 9,
        label: '',
        isTitle: true
    },
    {     id:1, 
        label: 'Audits',
        icon: 'bxs-check-shield',
        link: '/audits',
    },
    {  
        id:2,    
        label: 'Calendar',
        icon: 'bx-calendar-event',
        link: '/calendar',
    },
    {      
        label: 'Reports',
        icon:  'bxs-report',
        link:  '/reports',
    },
    // {      
    //     label: 'User Setup',
    //     icon: 'bx-cog',
    //     link: '/user-setup',
    // },
    {      
        label: 'Settings',
        icon:  'bx-cog',
        link:  '/Settings',
        subItems: [
            {
                id: 1,
                label: 'Site Groups',
                link: '/settings/groupsite',             
            },
            {
                id: 2,
                label: 'Contact Details',
                link: '/settings/contactdetails',           
            },
            // {
            //     id: 3,
            //     label: 'Site Profile',
            //     link: '/siteprofile',           
            // },
            {
                id: 3,
                label: 'User Administration',
                link: '/settings/user-administration',           
            },
            {
                id: 4,
                label: 'User Permissions',
                link: '/settings/user-permissions',           
            },
         
        ]
    },    
    {      
        label: 'Templates',
        icon:  'bxs-layout',
        link:  '/templates',
    },   
    // {      
    //     label: 'Categories',
    //     icon: ' bxs-widget',
    //     link: '/Categories',
    // }, 
    // {      
    //     label: 'Template Builder',
    //     icon:  ' bx-collection',
    //     link:  '/Template-Builder',
    // }, 
    {      
        label: 'Reviews',
        icon: 'bx-list-check',
        link: '/summary',
        subItems: [
            {
                id: 1,
                label: 'Summary',
                link: '/reviews/summary',             
            },
            {
                id: 2,
                label: 'Financial Review',
                link: '/reviews/financial-review',           
            },
            // {
            //     id: 3,
            //     label: 'Site Profile',
            //     link: '/siteprofile',           
            // },
            {
                id: 3,
                label: 'Transaction Review',
                link: '/reviews/transaction',           
            },
            {
                id: 4,
                label: 'Lot Audit',
                link: '/reviews/lot-audit',           
            },
            {
                id: 5,
                label: 'Audit',
                link: '/reviews/pdf-audits',           
            },
        ]
    }, 


 
];

